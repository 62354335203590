@font-face {
  font-family: "iconfont"; /* Project id 2530651 */
  src: url('iconfont.eot?t=1624847788158'); /* IE9 */
  src: url('iconfont.eot?t=1624847788158#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('iconfont.woff?t=1624847788158') format('woff'),
       url('iconfont.ttf?t=1624847788158') format('truetype'),
       url('iconfont.svg?t=1624847788158#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-denglu:before {
  content: "\e653";
}

.icon-zhuce:before {
  content: "\e6f2";
}

