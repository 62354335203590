


.compltet_tablecomp{
    position: absolute;
    top: 120px;
    /* bottom: 120px; */
    left: 7vw;
    /* margin:0 auto; */
    /* width: 1160px; */
    width: 86vw;
    /* height: 100%; */
    height: auto;
}


.details{
    /* border: 12px solid rgb(12, 230, 201); */
    display: flex;
    justify-content: center;
    /* background-image: url(); */
}

.drawtitle{
    position: absolute;
    top: 0;
    margin-top: 2px;
    padding: 18px 0 0px 18px;
    height: auto;
    width: calc(100% - 4px);
    z-index: 2;
    /* background-color: #e1e1e1; */
    /* box-sizing: content-box; */
}

.drawtitlebox{
    display: flex;
}

.drawimg{
    width: 60px;
    height: 60px;
    margin-left: 36px;
    border: 2px solid;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);


}

.search_title{
    font-size: 28px;
    line-height: 60px;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    user-select: none;
    margin-bottom: 10px;
    margin-left: 26px;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.7);

}

.drawdivider{
    margin: 6px 12px 0 12px;
    min-width: 26vw;
    width: 50vw;
    /* border: none; */
}


  

.drawcontent{
    /* position: relative; */
    /* top不计入高度 */
    /* top: 80px; */
    height: auto;
    margin-top: 78px;
    /* height: 297px; */
    max-height: calc(50vh - 102px);
    /* max-height: 30vh; */
    /* margin-left: 26px; */
    padding: 26px 44px 20px 44px;
    background-color: #e1e1e1;
    overflow-y: auto;
    overflow-x: hidden;
    /* box-shadow: 0px -22px 20px -12px #515151 inset; */

}

/* 隐藏原滚动条 */
/* .drawcontent::-webkit-scrollbar {
    display: none;
  }
  .drawcontent {
    -ms-overflow-style: none;
  } */
/*滚动条样式*/
.drawcontent::-webkit-scrollbar {
    width: 4px;
    /* z-index: 10006; */
  }
  
  /* 滚动条滑块 */
  .drawcontent::-webkit-scrollbar-thumb {
    /* border-radius: 10px; */
    /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
    background: rgba(0, 0, 0, 1);
  }
  
  /* .area:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 1);
  } */
  /* 滚动条底部轨道 */
  .drawcontent::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
    /* border-radius: 0; */
    /* background: rgba(222, 222, 222, 1); */
    background: transparent;
  }

.ant-drawer-content{
    overflow-x: hidden;
}

.alexisqq{
    width: 26px;
    height: 26px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    margin: 0 0 0 8px;
    /* cursor: pointer; */
    transition: all .3s;
    border-radius: 50%;

}

/* .alexisqq:hover{
    background-color: #000;

} */

/* .alexisqq:hover a{
    color: #f5f5f5;


} */

.alexisqq a{
    color: #000;

}

.alexisqq span{
    cursor: pointer;
    transition: all .3s;

}

.alexisqq span:hover{
    /* width: 26px; */
    /* height: 26px; */
    transform: scale(1.2);
}

.search_content{
    /* height: 16px; */
    font-size: 16px;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    line-height: 26px;
    padding-bottom: 16px;
    margin: 0 0 0 8px;
}

.search_content_rate{
    color: red;
    font-size: 20px;
    line-height: 20px;
    /* padding-bottom: 16px; */
    margin: 0 0 0 8px;

    
}

.search_content_rate_box{
    position: absolute;
    left: 30vw;
    display: flex;
}

.search_content_up{
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.search_content_down{
    /* margin-left: 16px; */
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.filldown{
    width: 16px;
    display: inline-block;
}

.search_content_not{
    color: red;
    font-size: 16px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    padding-bottom: 16px;
    margin: 0;
}

.search_content_paid_box{
    margin-left: 16px;
    position: relative;
    /* width: 50vw; */
    width: auto;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* vertical-align: middle; */
}

.search_content_free_box{
    margin-left: 16px;
    position: relative;
    /* width: 50vw; */
    width: auto;
    display: flex;
}

.search_content_paid{
    /* color: red; */
    /* background-image: url(./misc/Dollar.png); */
    width: 26px;
    height: 26px;
    /* line-height: 32px; */

    /* padding-bottom: 16px; */
    /* margin: 0; */
}

.search_content_free{
    width: 26px;
    height: 26px;
    /* line-height: 32px; */

    /* margin: 0; */
}

.ratebox{
    display: flex;
    width: auto;
}

.ratebox img{
    transition: all .3s;
  }

.ratebox img:hover{
    transform: scale(1.3);
  }

.ratesum{
    display: block;
    height: 26px;
    width: 36px;
    line-height: 26px;
    font-size: 12px;
    color: #515151;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
}

.closeimg{
    background-image: url(./misc/close.png);
    background-size: 26px;
    width: 26px;
    height: 26px;
    padding: 12px;
    cursor: pointer;
    z-index: 6;
    position: absolute;
    top: 3vh;
    right: 2vw;
}

/* 顶部logo栏目 */

.nav-top {
    display: flex;
    box-sizing: border-box;
    position: fixed;
    /* top: 0; */
    z-index: 9999;
    user-select: none;
    /* pointer-events: none; */
    border: 2px solid #000;
    height: 64px;
    width: 94.8vw;
    align-items: center;
    padding: 0 2vh 0 0;
    /* margin: 0 auto; */
    margin-bottom: 3vh;
    background-color: #f5f5f5;

    /* position: relative; */
    box-shadow: 0 25px 20px -10px rgba(0, 0, 0, .3);
    /* top: 0; */
}

.nav-top-back {
    /* display: flex; */
    box-sizing: border-box;
    position: fixed;
    top: 0;
    /* 设置为2白背景会被draw覆盖 */
    z-index: 2;
    user-select: none;
    pointer-events: none;
    /* border: 2px solid #000; */
    height: 60px;
    width: 94.8vw;
    /* align-items: center; */
    padding: 0;
    /* margin: 0 auto; */
    /* margin-bottom: 3vh; */
    background-color: #f5f5f5;

    /* position: relative; */
    /* box-shadow: 0 25px 20px -10px rgba(0, 0, 0, .3); */
    /* top: 0; */
}



.hanbot-logo{
    /* font-weight: bolder; */
    /* font-size: 36px; */
    height: auto;
    width: auto;
    text-align: center;
    border-right: 2px solid #000;

    /* display: inline-block;
    vertical-align: middle; */

}

.hanbot-logo img{
    height: 60px;
    width: 360px;
    /* display: inline-block;
    vertical-align: middle; */

}

.logobox{
    display: inline-block;
    border-right: 2px solid #000;

}

.logoboxup{
    height: 28px;
    width: 180px;
}

.logoboxupbox{
    border-bottom: 2px solid #000;
}

.logoboxdown{
    height: 30px;
    width: 180px;
}

.votemessagestyle .ant-message-notice-content{
    background-color: #f5f5f5;
    border: 2px solid #000;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.searchstyle{
    /* border: none!important; */
    /* outline:none !important; */
    /* background-color: grey; */
    margin: 0 0 0 26px;
    /* border-bottom: 3px solid #13227a!important; */
    border-bottom: 3px solid #000!important;

    position: absolute;
    right: 13vw;
    /* height: 36px;
    line-height: 36px; */
    /* user-select: none; */
    /* border-radius: 8px;
    flex: 1;
    text-indent: 1em;
    color: var(--color-3);
    outline: none;
    background-color: transparent;
    box-shadow: var(--box-shadow); */
}

.nav_notice{
    /* margin: 8px 0 0 26px; */
    font-size: 26px;
    position: absolute;
    right: 1vw;
    cursor: pointer;
    transition: all .3s;
    /* z-index: 10001; */
}

.nav_notice:hover{
    transform: scale(1.1);
}



.nav_feedback{
    font-size: 26px;
    position: absolute;
    right: 5vw;
    cursor: pointer;
    transition: all .3s;
}

.nav_feedback:hover{
    transform: scale(1.1);
}

.nav_ranking{
    font-size: 26px;
    position: absolute;
    right: 9vw;
    cursor: pointer;
    transition: all .3s;
}

.nav_ranking:hover{
    transform: scale(1.1);
}

.swingstyle{
    display: inline-block;
}

.rankingstyle{
    width: 100%;
    height: 32px;
    font-size: 26px;
    line-height: 32px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 8px;
    position: relative;
}

.rankingimgbox{
    display: inline-block;
    width: auto;
    height: 32px;
    line-height: 30px;
    position: absolute;
    left: 2vw;

}

.rankingtotal{
    display: inline-block;
    position: absolute;
    right: 2vw;
    height: 32px;
    width: auto;
    font-size: 12px;
    line-height: 32px;
    vertical-align: middle;
}

.rankingimg{
    width: 30px;
    height: 30px;
    vertical-align: middle;

}

.rankingimgheartbox{
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 26px;
    vertical-align: middle;

}

.rankingimgheart{
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.rankingsumgold{
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    color: rgb(244, 206, 113);
    /* text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,-1px -1px 0 #000; */
    /* font-size: 16px !important; */
}

.rankingsumsilver{
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    color: rgb(168, 194, 243);
    /* text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,-1px -1px 0 #000; */
    /* font-size: 16px !important; */
}

.rankingsumbronze{
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    color: rgb(244, 196, 142);
    /* text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,-1px -1px 0 #000; */
    /* font-size: 16px !important; */
}

.modalstyle{
    border: 2px solid #000;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    /* padding-bottom: 0; */

}

.ant-modal-content{
    border: 2px solid #000;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
}

.noticestyle{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    /* border: '2px solid #000', */
    border: 2px solid #000;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);

}

/* .feedbacktextarea{ */
    /* border: 2px solid #000; */
    /* border-radius: 10px; */
    /* box-shadow: 0 0 15px rgba(26, 25, 25, 0.7) inset; */
    /* padding: 10px; */
    /* font-size: 16px; */

/* } */

.feedbackstyle{
    background-color: #000;
    /* 提交按钮居中 */
    display: block;
    margin: 0 auto;
    border: none;
    /* font-size: 16px; */
    /* line-height: 16px; */
}

.feedbackstyle:hover{
    background-color: #000;
    border: none;
    transform: scale(1.2);
}

.feedbackstyleup{
    display: block;
    margin: 0 auto;
    border: none;
    font-size: 14px;
    line-height: 14px;
    background: #000;
}

.feedbackstyleup span{
    width: 14px;
    height: 14px;

}

/* .feedbackbox .ant-btn-primary {
    background: #000;
} */

.feedbackstylefinish{
    display: block;
    margin: 0 auto;
    border: none;
    background-color: green;
    cursor:default;
}

.feedbackstyle:active {
    border: none;
    background: #000;
}

.feedbackstyle:focus {
    border: none;
    background: #000;
}

.feedbackstyleup:hover{
    background-color: #000;

}

.feedbackstyleup:active {
    border: none;
    background-color: #000;

}

.feedbackstyleup:focus {
    border: none;
    background-color: #000;

}


.feedbackstylefinish:hover{
    background-color: green;

}

.feedbackstylefinish:active {
    border: none;
    background-color: green;

}

.feedbackstylefinish:focus {
    border: none;
    background-color: green;

}

.ant-input-affix-wrapper .ant-input:focus {
    border: none;
    /* border-bottom: 1px solid white !important; */
    box-shadow: none;
}

/* 去掉点击button时的动画 */
.ant-btn::after {
    border: 0 none;
    opacity: 0;
    animation:none 0 ease 0 1 normal;
  }

/* 中间hero区域 */
.area{
    /* overflow-y: auto; */
    /* overflow: hidden ; */
    /* margin: 4px; */
    overflow-y: overlay;
    /* overflow-y: auto; */
    overflow-x: hidden ;
    padding-top: 1vh;
    /* margin-top: 6px; */
    z-index: 1;
    /* display: inline-block; */
    position: relative;
    /* top: 1vh; */

}

.area{
    transition: all .3s;
  }

.champions-area{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    /* top:1vh; */
    z-index: 1;
    margin-top: 88px;
    /* padding-top: 6px; */
    /* overflow: scroll; */
}

.hero{
    box-sizing: border-box;
    margin: 0vh 1vh 2vh 1vh;
    /* z-index: 10001; */
    /* 去除div和img的多余距离 */
    font-size: 0;
    width: 124px;
    height: 124px;
    border: 2px solid #000;
    align-items: center;
    cursor: pointer;
    transition: all .3s;
    position: relative;
    /* width: 16%; */
}

.hero img{
  transition: all .3s;
}

/* .hero img{
    z-index: 10002;
} */

.hero:hover img{
  transform: scale(1.1);
}

/* .sumtip{
    font-size: 12px;
} */

.herocornersum{
    position:absolute;
    top: 0;
    right: 0;
    z-index: 6;
    height: 26px;
    width: 26px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #f5f5f5;
    /* background-color: #13227a; */
    background-color: #000;

    padding: 0 3px 0 3px;
    border-radius:0 0 0 30%;
    transition: all .3s;

}

.hero:hover .herocornersum{
    transform: scale(1.2);
  }

.bt_classify_name_empty{
    /* margin: 5px; */
    /* width: 16%; */
    margin: 0vh 1vh 0vh 1vh;
    width: 124px;
    height: 0px;
    font-size: 0;
  }


  .championbottomfill{
    /* position: fixed;
    bottom: 0; */
    width: 100%;
    height: 80px;
    /* background-color: #e01313; */
    /* background-image: linear-gradient(to top, #f5f5f5, transparent); */
    background-color:rgba(0,0,0,0);
}

/* 下方备案号 */
.footer {
    position: fixed;
    /* top:calc(50vh - 80px); */
    bottom: 0;
    z-index: 2;
    width: 94.8vw;
    height: 80px;
    /* height: 5vh; */
    /* #059fa1 */
    background-image: linear-gradient(to top, #f5f5f5, transparent);
    background-color:rgba(0,0,0,0);
    /* background-color: #f5f5f5; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    /* color: rgb(243, 243, 243); */
    /* color:#13227a; */
    color:#000;
    text-shadow: 3px 3px 3px #000;
    /* box-shadow: 0 0 16px #000; */
    font-family: 'yu100';
}

.footavatar{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    line-height: 16px;
}

.author{
    font-size: 13px;
}

.authorName,
.icpInfo {
    font-size: 12px;
    /* color: rgb(243, 243, 243); */
    /* color: #13227a; */
    color:#000;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}

/* .compltet_table .ant-table-wrapper{
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.6);
} */

.compltet_table .ant-table{
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.6);
}

:root {
    --white: #FFFFFF;
    --color-1: #E4EBF5;
    --color-2: #c8d0e7;
    --color-3: #9baacf;
    --box-shadow: inset 0.2rem 0.2rem 0.5rem var(--color-2), inset -0.2rem -0.2rem 0.5rem var(--white);
    --active-shadow : 0.3rem 0.3rem 0.6rem var(--color-2), -0.2rem -0.2rem 0.5rem var(--white);
}



/* input {
    height: 26px;
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: var(--box-shadow);
    margin: 0 13px;
    border-radius: 8px;
    flex: 1;
    text-indent: 1em;
    color: var(--color-3);
  } */
  /* .searchstyle::placeholder{
    color: var(--color-3);
  }
  .searchstyle:focus{
    box-shadow: var(--active-shadow)
  } */


  /* 搜索按钮 */
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    /* color: rgb(197 27 27 / 45%); */
    /* color: #13227a; */
    color: #000;

    /* background-color: grey; */
    border: none;
    /* background-color:rgba(245,245,245,1); */
    /* background-color: transparent; */
    background-color:#f5f5f5;
    /* transition: all .3s; */


}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) span{
    /* color: rgb(197 27 27 / 45%); */
    /* color: #13227a; */
    transition: all .3s;

}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary):hover span{
    /* color: rgb(197 27 27 / 45%); */
    /* color: #13227a; */
    transform: scale(1.3);
}

.searchcard{
    width: 180px;
    /* height: 200px; */
}

.searchback{
    color: #13227a;
    font-size: 26px;
    height: 32px;
    width: 44px;
    line-height: 32px;
    margin-left: 12px;
    margin-bottom: 6px;
    transition: all .3s;
    cursor: pointer;
}

.searchback:hover{
    font-size: 32px;
}

.search_list_item{
    border-bottom: 2px solid #bdbec0!important;
    /* cursor: pointer; */
    background-color: #fff;
    padding-left: 6px;
    margin-bottom: 10px;
    vertical-align: middle;
}

.search_opentitledetail{
    color: #13227a;
    cursor: pointer;
    font-size: 16px;
}





.searchmeta .ant-card-meta-title {
    overflow: hidden;
    font-weight: 800;
    font-size: 16px;
    color: #13227a!important;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.searchcardimg{
    width: 160px;
    height: 160px;
    margin: 0 auto;
    margin-top: 8px;

}

.searchspace{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-content: flex-start;
    margin-top: 26px;
    /* width: 90vw; */
}

.collectbtn{
    color: #13227a;
    font-size: 18px;
    /* padding-top: 12px; */
    /* margin: auto 0; */
    display: inline-block;
    vertical-align: middle;
}

.cornersum{
    position:absolute;
    top: 0;
    right: 0;
    z-index: 6;
    color: #f5f5f5;
    background-color: #13227a;
    padding: 0 3px 0 3px;
    border-radius:0 0 0 30%;
}

.intsum{
    display: inline-block;
    color: #fff;
    background-color: #13227a;
    border-radius: 16%;
    font-size: 12px;
    width: 26px;
    text-align: center;
    
}

/* .searchstyle .ant-input-group-addon{
    background-color: grey;

} */



/* .sousuobtn{
    margin: 0 auto;
    width: 110px;
    justify-content: center;
} */


.extrarowcom{
    display:inline-block;
    padding:10px;
    line-height: 28px;
    /* text-indent:2em; */
    margin:0px 0px 0px 1vw;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 700;
    width: 98%;
    /* background-color: rgb(255, 239, 211); */
    background: rgba(150,150,150,.1);
    /* border: 1px solid rgba(100,100,100,.2); */
    /* border-radius: 3px; */
}


div.ant-typography, .ant-typography p {
    margin-bottom: 0;
}

.tagcursor{
    cursor: pointer;
}

.opentip{
    height: 10px;
}

.editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }

  

/* ------------------------------------------------------------------------ */




/* .taskType {
    width: 100%;
    height: 70px;
    font-size: 36px;
    border-bottom: 3px solid rgb(243, 243, 243);
    display: flex;
    align-items: center;
    padding: 0 20px;
    user-select: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.8);
}
.taskTypeDark {
    color: rgb(207, 207, 207);
    border-bottom: 3px solid rgb(207, 207, 207);
}

/* 输入框包裹的盒子 */
/* .inputBox {
    height: 120px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgb(243, 243, 243);
}
.inputBoxDark {
    border-bottom: 2px solid rgb(207, 207, 207);
} */
/* 输入框 */
/* .inputTask {
    width: 100%;
    height: 50px;
    padding: 0 110px 0;
    font-size: 22px;
    background-color: #00c9b5;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    text-align: center;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
.inputTaskDark {
    background-color: #0d2a44;
    color: rgb(207, 207, 207);
}
.inputTask:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.8);
}
.inputTaskDark:hover {
    box-shadow: 0 0 16px rgba(0, 109, 211, 0.8);
}
/* ---------------------- */
.inputTask:focus {
    background-color: #009688;
}
.inputTaskDark:focus {
    background-color: #0e497c;
} */
/* --------------------------------------------------------- */
/* .inputTask:focus::-webkit-input-placeholder,
.inputTask::-webkit-input-placeholder {
    color: rgb(243, 243, 243);
}

.inputTask:focus:-moz-placeholder,
.inputTask::-moz-placeholder {
    color: rgb(243, 243, 243);
}

.inputTask:focus::-moz-placeholder,
.inputTask::-moz-placeholder {
    color: rgb(243, 243, 243);
}

.inputTask:focus:-ms-input-placeholder,
.inputTask::-ms-input-placeholder {
    color: rgb(243, 243, 243);
}
/* ------------------------------------------------------- */
.inputTaskDark:focus::-webkit-input-placeholder,
.inputTaskDark::-webkit-input-placeholder {
    color: rgb(207, 207, 207);
}

.inputTaskDark:focus:-moz-placeholder,
.inputTaskDark::-moz-placeholder {
    color: rgb(207, 207, 207);
}

.inputTaskDark:focus::-moz-placeholder,
.inputTaskDark::-moz-placeholder {
    color: rgb(207, 207, 207);
}

.inputTaskDark:focus:-ms-input-placeholder,
.inputTaskDark::-ms-input-placeholder {
    color: rgb(207, 207, 207);
}
/* ------------------------------------------------------- */

/* .taskNull {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    user-select: none;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.8);
}
.taskNullDark {
    color: rgb(207, 207, 207);
}
.taskNullIcon {
    font-size: 60px;
}
.taskNullText {
    font-size: 24px;
} */

/* 任务列表ul */
/* .taskBox {
    padding: 10px 0;
    margin-bottom: 0;
}

.taskBox li {
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    width: 90%;
    height: 50px;
    margin: 20px auto 20px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}

.taskBox li:hover {
    box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.8);
}
#taskLiDark:hover {
    box-shadow: 0 0 16px rgba(0, 109, 211, 0.8);
} */ 

/* 完成按钮 */
/* .taskDoneBtn {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    width: 50px;
    height: 50px;
    background-color: #00b4a2;
    box-shadow: 0 0 14px rgb(0, 0, 0) inset;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
.taskDoneBtnDark {
    background-color: #004b91;
    /* color: rgb(207, 207, 207); */
 /* } 
.taskDoneBtn:hover {
    background-color: rgb(0, 228, 38);
}
.taskDoneBtnDark:hover {
    background-color: rgb(0, 158, 26);
}  */

/* 编辑框 */
/* .taskBox li .taskEdit {
    border-radius: 25px;
    text-align: center;
    font-size: 22px;
    height: 50px;
    width: 100%;
    padding: 0 110px 0;
    background-color: #00c9b5;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    user-select: none;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
.taskBox li .taskEditDark {
    background-color: #0d2a44;
    color: rgb(207, 207, 207);
}

.taskBox li .taskEdit:focus {
    background-color: #009688;
}
.taskBox li .taskEditDark:focus {
    background-color: #0e497c;
} */

/* 转变时效按钮 */
/* .transTaskBtn {
    position: absolute;
    right: 50px;
    top: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    width: 50px;
    height: 50px;
    background-color: #00b4a2;
    box-shadow: 0 0 14px rgb(0, 0, 0) inset;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
.transTaskBtnDark {
    background-color: #004b91;
}
.transTaskBtn:hover {
    background-color: rgb(235, 231, 29);
}
.transTaskBtnDark:hover {
    background-color: rgb(202, 199, 11);
} */

/* 删除按钮 */
/* .taskDeleteBtn {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    width: 50px;
    height: 50px;
    background-color: #00b4a2;
    box-shadow: 0 0 14px rgb(0, 0, 0) inset;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
.taskDeleteBtnDark {
    background-color: #004b91;
}
.taskDeleteBtn:hover {
    background-color: red;
}
.taskDeleteBtnDark:hover {
    background-color: rgb(167, 0, 0);
} */ 

@media all and (max-width: 1024px) {
    .compltet_tablecomp{
        /* position: absolute; */
        /* top: 70px; */
        /* bottom: 120px; */
        left: 3vw;
        /* margin:0 auto; */
        /* width: 860px; */
        width: 93vw;
        /* height: 100%; */
        /* height: auto; */
    
    }
    .taskType {
        font-size: 20px;
        height: 50px;
    }
    .inputBox {
        height: 70px;
        width: 94%;
    }
    .inputTask {
        width: 100%;
        height: 32px;
        font-size: 16px;
        padding: 0 40px 0;
        border-radius: 16px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    }
    .taskNull {
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        user-select: none;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    }
    .taskNullIcon {
        font-size: 40px;
    }
    .taskNullText {
        font-size: 18px;
    }
    .taskBox li {
        border-radius: 16px;
        width: 94%;
        height: 32px;
        margin: 0 auto 16px;
    }
    .taskBox li .taskDoneBtn {
        font-size: 14px;
        width: 32px;
        height: 32px;
    }
    .taskBox li .taskEdit {
        border-radius: 16px;
        font-size: 16px;
        height: 32px;
        padding: 0 70px 0;
        pointer-events: none;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    }
    .transTaskBtn {
        right: 32px;
        font-size: 14px;
        width: 32px;
        height: 32px;
    }
    .taskBox li .taskDeleteBtn {
        font-size: 14px;
        width: 32px;
        height: 32px;
    }
    .bottomfill{
        width: 100%;
        height: 50px;
    }
}

@media all and (max-height: 820px) {
    .bottomfill{
        width: 100%;
        height: 50px;
    }
    .compltet_tablecomp{
        /* position: absolute; */
        top: 70px;
        /* bottom: 120px; */
        /* left: 10vw; */
        /* margin:0 auto; */
        /* width: 1160px; */
        /* height: 100%; */
        /* height: auto; */
    
    }
}