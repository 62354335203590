html{
    /* min-width: 1200px; */
    background-color: #f5f5f5;

}
body{
    background-color: #f5f5f5;
    padding: 2vw 2.6vw;
    /* min-width: 1200px; */
    width: 100% !important;
    height: auto;
    /* box-shadow: 15px 0px 15px -15px rgba(26, 25, 25, 0.7) inset,
               -15px 0px 15px -15px rgba(26, 25, 25, 0.7) inset; */
}


/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
/* ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  } */
  /*定义滑块 内阴影+圆角*/
  /* ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,1);
    border-radius: 10px;
  } */
  /*定义滚动条轨道 内阴影+圆角*/
  /* ::-webkit-scrollbar-track {
    border-radius: 10px;
  } */

.background {
    /* background: url(./sun.jpg) 0 / cover fixed; */
    background-color: #f5f5f5;
    /* background-size: cover; */
}
/* #backgroundDark {
    background: url(./dark.jpg) 0 / cover fixed;
} */
.appBox {
    /* position: relative; */
    /* 80px为footer之前高度，故总有一个白边 */
    min-height: calc(100vh - 80px);
    width: 100%;
    /* height: 100%; */
    /* height:95vh; */
    padding-bottom: 160px;
}

@media all and (max-width: 1024px) {
    .appBox {
        min-height: 100vh;
    }
}
